.wrapper {
  background-color: #dedede;
  padding: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

.tooltip {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  margin-left: 3px;
  padding: 15px;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
}

.legend {
  font-family: Arial, Helvetica, sans-serif;
}

.foreignObject {
  font: 8px sans-serif;
  text-overflow: ellipsis;
  text-align: left;
  word-wrap: break-word;
}

.labelbody {
  margin: 0px;
  padding: 5px;
}
